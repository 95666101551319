<form
  #form
  (ngSubmit)="submit()"
  ngNativeValidate
  [appApiAction]="formPromise"
  [formGroup]="exportForm"
>
  <div class="page-header">
    <h1>{{ "exportVault" | i18n }}</h1>
  </div>

  <app-callout type="error" title="{{ 'vaultExportDisabled' | i18n }}" *ngIf="disabledByPolicy">
    {{ "personalVaultExportPolicyInEffect" | i18n }}
  </app-callout>
  <app-export-scope-callout
    [organizationId]="organizationId"
    *ngIf="!disabledByPolicy"
  ></app-export-scope-callout>

  <div class="row">
    <div class="form-group col-6">
      <label for="format">{{ "fileFormat" | i18n }}</label>
      <select class="form-control" id="format" name="Format" formControlName="format">
        <option *ngFor="let f of formatOptions" [value]="f.value">{{ f.name }}</option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="form-group col-6">
      <app-user-verification ngDefaultControl formControlName="secret" name="secret">
      </app-user-verification>
    </div>
  </div>
  <button
    type="submit"
    class="btn btn-primary btn-submit"
    [disabled]="form.loading || exportForm.disabled"
  >
    <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
    <span>{{ "exportVault" | i18n }}</span>
  </button>
</form>
