<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="deleteAccountTitle">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <form
      class="modal-content"
      #form
      (ngSubmit)="submit()"
      [appApiAction]="formPromise"
      ngNativeValidate
    >
      <div class="modal-header">
        <h2 class="modal-title" id="deleteAccountTitle">{{ "deleteAccount" | i18n }}</h2>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          appA11yTitle="{{ 'close' | i18n }}"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{ "deleteAccountDesc" | i18n }}</p>
        <app-callout type="warning">{{ "deleteAccountWarning" | i18n }}</app-callout>
        <app-user-verification [(ngModel)]="masterPassword" ngDefaultControl name="secret">
        </app-user-verification>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-danger btn-submit" [disabled]="form.loading">
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
          <span>{{ "deleteAccount" | i18n }}</span>
        </button>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
          {{ "close" | i18n }}
        </button>
      </div>
    </form>
  </div>
</div>
