<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="billingSyncTitle">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <form
      class="modal-content"
      #form
      (ngSubmit)="submit()"
      [appApiAction]="formPromise"
      ngNativeValidate
    >
      <div class="modal-header">
        <h2 class="modal-title" id="billingSyncTitle">{{ "manageBillingSync" | i18n }}</h2>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          appA11yTitle="{{ 'close' | i18n }}"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{ "billingSyncKeyDesc" | i18n }}</p>
        <div class="form-group">
          <label for="billingSyncKey"
            >{{ "billingSyncKey" | i18n }} <small>(</small><small>{{ "required" | i18n }}</small
            ><small>)</small></label
          >
          <input
            id="billingSyncKey"
            type="input"
            name="billingSyncKey"
            class="form-control"
            [(ngModel)]="billingSyncKey"
            required
            appAutofocus
            appInputVerbatim
          />
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
          <span>{{ "save" | i18n }}</span>
        </button>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
          {{ "cancel" | i18n }}
        </button>
        <div class="ml-auto">
          <button
            #deleteBtn
            type="button"
            (click)="deleteConnection()"
            class="btn btn-outline-danger"
            appA11yTitle="{{ 'delete' | i18n }}"
            [disabled]="form.loading"
          >
            <i class="bwi bwi-trash bwi-lg bwi-fw" [hidden]="form.loading" aria-hidden="true"></i>
            <i
              class="bwi bwi-spinner bwi-spin bwi-lg bwi-fw"
              [hidden]="!form.loading"
              title="{{ 'loading' | i18n }}"
              aria-hidden="true"
            ></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
