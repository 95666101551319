<div class="tabbed-header">
  <h1>{{ "twoStepLogin" | i18n }}</h1>
</div>
<p *ngIf="!organizationId">{{ "twoStepLoginDesc" | i18n }}</p>
<p *ngIf="organizationId">{{ "twoStepLoginOrganizationDesc" | i18n }}</p>
<bit-callout type="warning" *ngIf="!organizationId">
  <p>{{ "twoStepLoginRecoveryWarning" | i18n }}</p>
  <button bitButton buttonType="secondary" (click)="recoveryCode()">
    {{ "viewRecoveryCode" | i18n }}
  </button>
</bit-callout>
<h2 [ngClass]="{ 'mt-5': !organizationId }">
  {{ "providers" | i18n }}
  <small *ngIf="loading">
    <i
      class="bwi bwi-spinner bwi-spin bwi-fw text-muted"
      title="{{ 'loading' | i18n }}"
      aria-hidden="true"
    ></i>
    <span class="sr-only">{{ "loading" | i18n }}</span>
  </small>
</h2>
<bit-callout type="warning" *ngIf="showPolicyWarning">
  {{ "twoStepLoginPolicyUserWarning" | i18n }}
</bit-callout>
<ul class="list-group list-group-2fa">
  <li *ngFor="let p of providers" class="list-group-item d-flex align-items-center">
    <div class="logo-2fa d-flex justify-content-center">
      <img [class]="'mfaType' + p.type" [alt]="p.name + ' logo'" />
    </div>
    <div class="mx-4">
      <h3 class="mb-0">
        {{ p.name }}
        <ng-container *ngIf="p.enabled">
          <i
            class="bwi bwi-check text-success bwi-fw"
            title="{{ 'enabled' | i18n }}"
            aria-hidden="true"
          ></i>
          <span class="sr-only">{{ "enabled" | i18n }}</span>
        </ng-container>
        <app-premium-badge *ngIf="p.premium"></app-premium-badge>
      </h3>
      {{ p.description }}
    </div>
    <div class="ml-auto">
      <button
        bitButton
        buttonType="secondary"
        [disabled]="!canAccessPremium && p.premium"
        (click)="manage(p.type)"
      >
        {{ "manage" | i18n }}
      </button>
    </div>
  </li>
</ul>
<form *ngIf="!loading" #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate>
  <div class="row">
    <div class="col-12">
      <h2 class="mt-5 spaced-header">
        {{ "deviceVerification" | i18n }}
      </h2>
      <div class="form-group">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="enableDeviceVerification"
            name="enableDeviceVerification"
            disabled="{{ !isDeviceVerificationSectionEnabled }}"
            [(ngModel)]="enableDeviceVerification"
          />
          <label class="form-check-label" for="enableDeviceVerification">
            {{ "enableDeviceVerification" | i18n }}
          </label>
        </div>
        <small class="form-text text-muted">{{ "deviceVerificationDesc" | i18n }}</small>
      </div>
      <button
        type="submit"
        bitButton
        buttonType="primary"
        class="btn-submit"
        [disabled]="form.loading"
        *ngIf="isDeviceVerificationSectionEnabled"
      >
        <i
          class="bwi bwi-spinner bwi-spin"
          title="{{ 'loading' | i18n }}"
          aria-hidden="true"
          *ngIf="form.loading"
        ></i>
        <span>{{ "save" | i18n }}</span>
      </button>
    </div>
  </div>
</form>

<ng-template #authenticatorTemplate></ng-template>
<ng-template #recoveryTemplate></ng-template>
<ng-template #duoTemplate></ng-template>
<ng-template #emailTemplate></ng-template>
<ng-template #yubikeyTemplate></ng-template>
<ng-template #webAuthnTemplate></ng-template>
